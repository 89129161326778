import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SearchInput } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const SuggestionsExample = () => {
  const fruits = ['Apple', 'Apricot', 'Avocado', 'Banana', 'Blackberry', 'Blueberry', 'Cherry', 'Coconut', 'Cranberry', 'Date', 'Dragonfruit', 'Fig', 'Gooseberry', 'Grape', 'Honeyberry', 'Jackfruit', 'Kiwifruit', 'Lemon', 'Lime', 'Mango', 'Melon', 'Nectarine', 'Orange', 'Papaya', 'Passionfruit', 'Peach', 'Pear', 'Pineapple', 'Raspberry', 'Satsuma', 'Start fruit', 'Strawberry', 'Tangelo', 'Tomato'].map(fruit => ({
    value: fruit
  }));
  const getSuggestions = inputValue => new Promise((resolve, reject) => {
    const filteredItems = fruits.filter(fruit => {
      return fruit.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    });
    setTimeout(() => resolve(filteredItems), 3000);
  });
  return <SearchInput label="Search for a fruit" placeholder="E.g. Apple" helperText="Assistive text" searchButtonAriaLabel="Search" clearButtonAriaLabel="Clear search field" suggestionLabelField="value" getSuggestions={getSuggestions} onSubmit={submittedValue => console.log('Submitted value:', submittedValue)} style={{
    maxWidth: '320px'
  }} mdxType="SearchInput" />;
};
export const HighlightExample = () => {
  const educations = ['Daycare education', 'Preschool education', 'Basic education', 'Upper secondary education', 'Vocational education', 'Polytechnic education', 'University education', 'Adult education centre'].map(education => ({
    value: education
  }));
  const getSuggestions = inputValue => new Promise((resolve, reject) => {
    const filteredItems = educations.filter(education => {
      return education.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    });
    resolve(filteredItems);
  });
  return <>
      <div style={{
      fontSize: 'var(--fontsize-body-m)',
      marginBottom: 'var(--spacing-m)'
    }}>To see highlighting in action, search for "education".</div>
      <SearchInput label="Search education services" helperText="Assistive text" searchButtonAriaLabel="Search" clearButtonAriaLabel="Clear search field" highlightSuggestions suggestionLabelField="value" getSuggestions={getSuggestions} onSubmit={submittedValue => console.log('Submitted value:', submittedValue)} style={{
      maxWidth: '320px'
    }} mdxType="SearchInput" />
    </>;
};
export const _frontmatter = {
  "slug": "/components/search-input",
  "title": "SearchInput",
  "navTitle": "SearchInput"
};
const layoutProps = {
  SuggestionsExample,
  HighlightExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SearchInput label="Search" placeholder="Placeholder text" helperText="Assistive text" searchButtonAriaLabel="Search" clearButtonAriaLabel="Clear search field" onSubmit={submittedValue => console.log('Submitted value:', submittedValue)} style={{
        maxWidth: '320px'
      }} mdxType="SearchInput" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label should always be provided with a search input.`}</strong>{` Only exception to this is when search input is used in navigation.`}
        <ul parentName="li">
          <li parentName="ul">{`Make sure that the label is clear and concise. The user should immediately understand what the search is for.`}</li>
          <li parentName="ul">{`Instead of a label, you may provide a button next to the input which states the purpose of the search.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Search field input should fit into one line. Do not use multiline search inputs.`}</li>
      <li parentName="ul"><strong parentName="li">{`It is recommended to have only one search per page.`}</strong>{` If you need multiple searches, clearly label them to indicate their purpose.`}</li>
      <li parentName="ul">{`If possible, provide search suggestions (see With suggestions section for more info). This helps users to find what they are looking for, especially if the searchable items are complex.`}
        <ul parentName="li">
          <li parentName="ul">{`Do not use suggestions to present search results. Results should be provided in their own component or a separate page.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The basic version of the search input is a plain text field with search and clear buttons. The search button will initiate the search with the current text input.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SearchInput label="Search" placeholder="Placeholder text" helperText="Assistive text" searchButtonAriaLabel="Search" clearButtonAriaLabel="Clear search field" onSubmit={submittedValue => console.log('Submitted value:', submittedValue)} style={{
        maxWidth: '320px'
      }} mdxType="SearchInput" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-suggestions",
      "style": {
        "position": "relative"
      }
    }}>{`With suggestions`}<a parentName="h4" {...{
        "href": "#with-suggestions",
        "aria-label": "with suggestions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If available, suggestions can also be included with the HDS search input. Suggestions are generated by a project supplied function which means it is up to the project to choose how and where the suggestions are made.`}</p>
    <p>{`When fetching suggestions is taking longer than 1.5 seconds, a loading spinner will be displayed below the input until suggestions are received.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SuggestionsExample mdxType="SuggestionsExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-suggestions-and-highlighting",
      "style": {
        "position": "relative"
      }
    }}>{`With suggestions and highlighting`}<a parentName="h4" {...{
        "href": "#with-suggestions-and-highlighting",
        "aria-label": "with suggestions and highlighting permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS search input also supports suggestion highlighting. This means that the suggestion not part of the initial search keyword is highlighted. In some cases this can be beneficial to the user to differentiate between search suggestions.`}</p>
    <p>{`Suggestion highlighting is activated with `}<inlineCode parentName="p">{`highlightSuggestions`}</inlineCode>{` property and it becomes visible after at least three characters are typed into the input.`}</p>
    <p>{`Note! This feature is experimental and we are looking to improve it after project feedback.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <HighlightExample mdxType="HighlightExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      